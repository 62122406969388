import { render, staticRenderFns } from "./SupplierCompanyCreateEdit.vue?vue&type=template&id=c5b9ab46&scoped=true&"
import script from "./SupplierCompanyCreateEdit.vue?vue&type=script&lang=js&"
export * from "./SupplierCompanyCreateEdit.vue?vue&type=script&lang=js&"
import style0 from "./SupplierCompanyCreateEdit.vue?vue&type=style&index=0&id=c5b9ab46&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b9ab46",
  null
  
)

export default component.exports